import React from "react"
import { Flex, Heading, Button } from "theme-ui"
import Link from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Flex sx={{
      py: 6,
      height: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center"
    }}>
      <Heading pb={4}>You just hit a route that doesn&#39;t exist...</Heading>
      <Button as={Link} to="/">Go back</Button>
    </Flex>
  </Layout>
)

export default NotFoundPage
